body {
  background:
    fixed left bottom url(../src/assets/images/back-body1.png) no-repeat,
    /* fixed right bottom url(../src/assets/images/back-body2.png) no-repeat,
    fixed right 25% url(../src/assets/images/back-body3.png) no-repeat, */
    fixed center / cover url(../src/assets/images/bg.png) no-repeat !important;
  /* min-height: 100vh; */
  font-family: Inter;
  margin: 0;
  /* overflow: overlay !important; */
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #93cff8;
}

::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 10px;
}

@font-face {
  font-family: Jura;
  src: url("./assets/font/Jura.ttf");
}

@font-face {
  font-family: JosefinSans;
  src: url("./assets/font/JosefinSans.ttf");
}

@font-face {
  font-family: Lato;
  src: url("./assets/font/Lato-Regular.ttf");
}

.App {
  padding: 0 176px 70px 176px;
  font-family: Inter;
}

.Stepper svg, 
.Stepper text {
  color: white !important;
  fill: white !important;
  font-family: Jura !important;
}

.MuiStepConnector-root {
  display: none !important;
  font-family: Jura !important;
}

.SwapPage .am5-layer-30 {
  display: none !important;
  font-family: Jura !important;
}

canvas.am5-layer-30 {
  display: none;
}

main {
  position: relative;
  box-sizing: border-box;
  border-radius: 64px;
  min-height: 100vh;
  padding-top: 120px !important;
  border: 16px solid transparent;
  background: 
    fixed left bottom url(../src/assets/images/back-body1.png) no-repeat,
    /* fixed right bottom url(../src/assets/images/back-body2.png) no-repeat,
    fixed right 25% url(../src/assets/images/back-body3.png) no-repeat, */
    linear-gradient(#1C1631, #1C1631) padding-box,
    linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
}

/* main:before {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: 16px;
  border-radius: 52px;
  background: 
    fixed left bottom url(../src/assets/images/back-body1.png) no-repeat,
    fixed right bottom url(../src/assets/images/back-body2.png) no-repeat,
    fixed right 25% url(../src/assets/images/back-body3.png) no-repeat,
    #1C1631;
  pointer-events: none;
} */

main:after {
  content: '';
  position: absolute;
  top: 0; right: 0; bottom: 0; left: 0;
  margin: -26px;
  background: 
    left top url(../src/assets/images/corner.png) no-repeat,
    left bottom url(../src/assets/images/corner.png) no-repeat,
    right top url(../src/assets/images/corner.png) no-repeat,
    right bottom url(../src/assets/images/corner.png) no-repeat;
  pointer-events: none;
}

/* modal */
.MuiDialog-root .MuiModal-backdrop {
  background-color: #a692ce63;
  backdrop-filter: blur(3px);
}

.MuiDialog-root .MuiDialog-paper {
  border-radius: 32px;
  box-shadow: 0px 15px 32px 0px #0000001A;
  background: linear-gradient(#1C1631, #1C1631) padding-box,
    linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
  border: 1px solid transparent;
}

.MuiDialog-root .MuiDialogTitle-root {
  font-family: 'Riffic Free';
  font-size: 24px;
  padding: 32px;
  display: flex;
  justify-content: space-between;
}

.MuiDialog-root .MuiDialogContent-root {
  padding: 0 32px 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.MuiDialog-root .MuiDialogActions-root {
  padding: 0 32px 32px;
  display: flex;
  justify-content: space-between;
  gap: 1rem;
}

.MuiDialog-root .MuiButton-root {
  padding: 12px;
  border-radius: 16px;
  text-transform: none;
}

.MuiButton-root.MuiButton-colorPrimary {
  background-color: #E12D85;
  color: white;
}

.MuiButton-root.MuiButton-colorSecondary {
  background-color: #FFFFFF1F;
  color: white;
}

.MuiButton-root.MuiButton-colorPrimary:not(:disabled):hover {
  background-color: #E12D8588;
  color: white;
}

.MuiButton-root.MuiButton-colorSecondary:not(:disabled):hover {
  background-color: #FF990008;
  color: white;
}

.MuiButton-root:disabled, .MuiButton-root:disabled:hover {
  background-color: #7373733b;
  color: #818181;
}

.MuiButton-root:disabled:hover {
  cursor: not-allowed;
}

main [id^=tradingview_][id$=-wrapper] {
  border-radius: 16px;
  overflow: hidden;
  border: 8px solid #2a2e39;
}

main .tradingview-widget-copyright {
  display: none;
}


@media (max-width: 1280px) {
  body {
    font-size: 13px;
  }
}

@media (max-width: 1520px) {
  body {
    background: fixed center / cover url(../src/assets/images/bg-sm.png) no-repeat !important;
    font-size: 12px;
  }
  .App {
    padding: 16px;
    padding-top: 56px;
  }  
  main {
    border-width: 8px;
    border-radius: 25px;
    padding-top: 120px !important;
    background: 
      linear-gradient(#1C1631, #1C1631) padding-box,
      linear-gradient(90deg, #FF8E08 0.14%, #FDFFB3 11.87%, #FFED4C 23.15%, #FBE4A8 54.37%, #F8D185 74.22%, #E66606 79.32%, #FCF3A0 86.96%, #FF7629 100%) border-box;
  }
  main:after {
    background-size: 25px;
    margin: -10px;
  }
  .site-logo {
    top: 70px !important;
  }
}

@media (max-width: 600px) {
  body {
    font-size: 12px;
  }
  main {
    padding-top: 160px !important;
  }
  .MuiDialog-root .MuiDialog-paper {
    margin: 24px;
    /* width: 100vw;
    align-self: flex-end;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
  }
  .App {
    padding-top: 8px;
  } 
  .site-logo {
    top: 74px !important;
    img {
      height: 76px;
    }
  }
}
